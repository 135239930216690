<template>
<div class="container">
	<div>
		<p class="strong" style="text-align:center">叁零荟酒友会会员规则</p>
		<p>（2023年11月24）</p>
		<p>
		1、叁零荟酒友会：我们严格遵守“3个0”标准：“0兑0加0塑”（0食用酒精勾兑、0化学添加剂、0塑化剂添加），确保源头酒厂杜绝人为故意添加食用酒精、化学添加剂和塑化剂，倡导健康白酒理念，致力于保护公众健康。我们与茅台镇核心厂区靠谱酒厂合作，致力于源头直采，为会员筛选符合“3个0”标准的优质酒源，做好品控，向会员提供品质可靠、高性价比的酒品，并提供个性化定制服务和封坛服务。</p>
		<p>2、按照会员累计消费金额确定等级，享受不同的优惠会员价：<br>
		a)普通会员：青铜级：扫码注册；白银级：累计消费满0.5万元；黄金级：累计消费满1万元；钻石级：累计消费满1.5万元。<br/>
		b)VIP会员：对于认同并愿意推广叁零荟理念的杰出人士（专家教授、博士、企业家、社会名流等），经会长推荐和邀请、平台审核后，授予VIP会员资格，直接享受永久钻石级会员价，无消费金额要求。对于同意平台宣传展示照片和身份的VIP会员，平台将每年赠送1万元小礼包：2箱“良师益友”款酱香白酒（青铜会员价）。<br>
		c)钻石直通车：会员充值499元，直接享受钻石级会员价格待遇，有效期1年；期满续费即可继续享受钻石级待遇。当充值会员累计消费满1.5万元时，即获得钻石级永久资格，无需再充值，平台自动退还最近一期充值款499元。</p>
		<p>3、会员积分<br>
		a)会员扫码注册成功后，可在微信小程序“三零荟”上操作积分查询、兑换、提现。会员根据下表所获积分，自动累积计算。<br>
		b)积分兑换：按1积分=1元人民币计算，可兑换酒品/提现。如开发票给平台，可全额提现；如不开票，提现时平台代扣代缴个税。</p>
		
		
		<table>
			<tr>
				<td  rowspan = 2>序号</td>
				<td  rowspan = 2>酒品</td>
				<td  rowspan = 2>规格型号(瓶)</td>
				<td  colspan = 2>会员积分（瓶）</td>
			
			</tr>
			<tr><td>直接会员消费时推荐人获得的积分</td>
			<td>次接会员消费时推荐人获得的积分</td></tr>
			<tr>
				<td>1</td>
				<td>良师益友	500ml/瓶</td>
				<td>纸箱1*6</td>
				<td>20</td>
				<td>5</td>
			</tr>
			<tr>
				<td>2</td>
				<td>志同道合	500ml/瓶</td>
				<td>纸箱1*6</td>
				<td>12</td>
				<td>3</td>
			</tr>
			<tr>
				<td>3</td>
				<td>风华正茂	500ml/瓶</td>
				<td>纸箱1*6</td>
				<td>8</td>
				<td>2</td>
			</tr>
		</table>
		<p>4、本规则为《叁零荟用户注册和服务协议》的附件，为其不可分割的组成部分；本规则未尽事宜，适用《叁零荟用户注册和服务协议》条款的约定。</p>
			
		<p style="text-align: right; padding-top: 20px;">上海叁個零科技有限公司</p>
		<p style="text-align: right;">2023年11月24日</p>

	</div>
</div>
</template>

<script>
	
export default {
	name: 'index',
	components: {
	},
	data() {
		return {
		title:"三个零",
		}
	},
	created() {

	},
	methods: {

	} 
}
</script>

<style>
	p{
		margin: 5px 0;
		line-height: 1.5;
	}
	.container {
		padding: 10px;
	}
	.strong{
		font-weight: bold;
	}
	.item{
		margin-top: 10px;
	}
	table {
		border: 1px solid black;
		border-collapse: collapse;
		width: 100%;
	}
	
	th, td {
		border: 1px solid black;
		padding: 10px;
		text-align: center;
	}
	
	th {
		background-color: #ccc;
	}
	
	td {
		background-color: #fff;
	}
</style>
